var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',[_c('a',{staticClass:"button is-text",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-icon',{attrs:{"type":"is-secondary","icon":"chevron-left"}}),_c('span',[_vm._v("  página anterior")])],1)]),_c('div',{staticClass:"columns is-mobile is-centered"},[_c('div',{staticClass:"column is-10-mobile is-6-tablet is-4-desktop"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('h1',{staticClass:"title is-4 has-text-centered"},[_vm._v("Contacte-nos")]),_c('div',{staticClass:"block"},[_c('label',{staticClass:"label"},[_vm._v("Motivo do contacto")]),_c('b-radio',{attrs:{"type":"is-dark","name":"type","native-value":"contact","selected":""},on:{"input":_vm.selectContact},model:{value:(_vm.contact_form.type),callback:function ($$v) {_vm.$set(_vm.contact_form, "type", $$v)},expression:"contact_form.type"}},[_vm._v("Contacto")]),_c('b-radio',{attrs:{"type":"is-dark","name":"type","native-value":"suggestion"},on:{"input":_vm.selectContact},model:{value:(_vm.contact_form.type),callback:function ($$v) {_vm.$set(_vm.contact_form, "type", $$v)},expression:"contact_form.type"}},[_vm._v("Sugestão")]),_c('b-radio',{attrs:{"type":"is-dark","name":"type","native-value":"problem"},on:{"input":_vm.selectContact},model:{value:(_vm.contact_form.type),callback:function ($$v) {_vm.$set(_vm.contact_form, "type", $$v)},expression:"contact_form.type"}},[_vm._v("Problema")])],1),(_vm.showContact)?_c('b-field',{attrs:{"label":"Email"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","name":"email"},model:{value:(_vm.contact_form.email),callback:function ($$v) {_vm.$set(_vm.contact_form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contact_form.email"}}),(errors.length > 0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('b-field',{attrs:{"label":"Nome"}},[_c('ValidationProvider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","name":"name"},model:{value:(_vm.contact_form.name),callback:function ($$v) {_vm.$set(_vm.contact_form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contact_form.name"}}),(errors.length > 0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Assunto"}},[_c('ValidationProvider',{attrs:{"name":"Assunto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","name":"subject"},model:{value:(_vm.contact_form.subject),callback:function ($$v) {_vm.$set(_vm.contact_form, "subject", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contact_form.subject"}}),(errors.length > 0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Mensagem"}},[_c('ValidationProvider',{attrs:{"name":"Mensagem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"textarea","name":"message"},model:{value:(_vm.contact_form.message),callback:function ($$v) {_vm.$set(_vm.contact_form, "message", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contact_form.message"}}),(errors.length > 0)?_c('span',{staticClass:"v-error"},[_vm._v(_vm._s(errors[0])+"!")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"has-text-right"},[_c('b-button',{staticClass:"is-primary",attrs:{"loading":_vm.buttonLoading},on:{"click":_vm.submitContact}},[_vm._v("Enviar")])],1)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }